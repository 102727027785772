import axios from 'axios'
import { updateVuetifyLightTheme } from '@/plugins/vuetify'
import { translations } from '../common'
import conf from './conf'
import store from '../../store'

const configData = {}

function getConfigResponse() {
  return axios.get(`${conf.value('VUE_APP_GRAPHQL_HTTP')}config`)
}

export function loadConfig(vuetifyInstance, callback) {
  if (Object.keys(configData).length === 0) {
    getConfigResponse().then(success => {
      // check client version
      store.dispatch('clientVersionCheck', success.headers['tom-client-version'])

      // set config
      const colors = JSON.parse(success.data.filter(prop => prop.key === 'colors')[0].value)
      updateVuetifyLightTheme(vuetifyInstance, colors)

      configData.logoFilename = success.data.filter(prop => prop.key === 'logoFilename')[0].value

      configData.lunchTimes = JSON.parse(success.data.filter(prop => prop.key === 'lunchTimeJSON')[0].value)
      configData.lunchTimes.forEach(minuteValue => {
        minuteValue.text = translations[minuteValue.label]
      })

      configData.securityTypes = JSON.parse(success.data.filter(prop => prop.key === 'securityTypes')[0].value)
      configData.securityTypes.forEach(type => {
        type.label = translations[type.label]
      })

      configData.questions = JSON.parse(success.data.filter(prop => prop.key === 'questionsJSON')[0].value)

      configData.mobileDriverShowDDTButton = success.data.filter(prop => prop.key === 'mobileDriverShowDDTButton')[0].value === 'true'

      configData.exportReports = JSON.parse(success.data.filter(prop => prop.key === 'exportReports')[0].value)

      configData.availableMaterials = [{ value: 'Tappeto' }, { value: 'Binder' }, { value: 'Base' }, { value: 'Cementato' }, { value: 'Stabilizzato' }]
      configData.availableMaterials.forEach(mat => {
        mat.text = translations[mat.value.toLowerCase()]
      })

      configData.foremanCategories = JSON.parse(success.data.filter(prop => prop.key === 'foremanCategories')[0].value)

      configData.pileTypes = JSON.parse(success.data.filter(prop => prop.key === 'pileTypes')[0].value)

      configData.teamTypes = JSON.parse(success.data.filter(prop => prop.key === 'teamTypes')[0].value)

      configData.fixedProjectScheduleHeight = success.data.find(prop => prop.key === 'fixedProjectScheduleHeight').value === 'true'

      configData.plannerResourcesSelectedOnBottom = success.data.find(prop => prop.key === 'plannerResourcesSelectedOnBottom').value === 'true'

      configData.draggableSchedule = success.data.find(prop => prop.key === 'draggableSchedule').value === 'true'

      configData.useBiggerFontInSchedule = success.data.find(prop => prop.key === 'useBiggerFontInSchedule').value === 'true'

      configData.showProjectDescriptionInSchedule = success.data.find(prop => prop.key === 'showProjectDescriptionInSchedule').value === 'true'

      configData.closeProgramHeader = success.data.find(prop => prop.key === 'closeProgramHeader').value === 'true'

      configData.activityRequestStatuses = JSON.parse(success.data.filter(prop => prop.key === 'activityRequestStatuses')[0].value)

      configData.vehicleRequestStatuses = JSON.parse(success.data.filter(prop => prop.key === 'vehicleRequestStatuses')[0].value)

      configData.vehicleOperations = JSON.parse(success.data.find(prop => prop.key === 'vehicleOperations').value)

      configData.resetAllowanceOnWeekend = success.data.find(prop => prop.key === 'resetAllowanceOnWeekend').value === 'true'

      configData.dpiStatuses = JSON.parse(success.data.filter(prop => prop.key === 'dpiStatuses')[0].value)

      configData.clockInTransferIsEnabled = success.data.find(prop => prop.key === 'clockInTransferIsEnabled').value === 'true'

      configData.clockInGeoRefIsEnabled = success.data.find(prop => prop.key === 'clockInGeoRefIsEnabled').value === 'true'

      configData.priorities = JSON.parse(success.data.find(prop => prop.key === 'priorities').value)

      configData.plannerEyeIsEnabled = success.data.find(prop => prop.key === 'plannerEyeIsEnabled').value === 'true'

      configData.showClockInTimeAlert = success.data.find(prop => prop.key === 'showClockInTimeAlert').value === 'true'

      configData.lunchBreak = JSON.parse(success.data.filter(prop => prop.key === 'lunchBreak')[0].value)

      configData.plannerCopyWholeDayIsEnabled = success.data.find(prop => prop.key === 'plannerCopyWholeDayIsEnabled').value === 'true'

      callback()
    })
  } else {
    callback()
  }
}

export function getLogoFilename() {
  return configData.logoFilename
}

export function getLunchTimes() {
  return configData.lunchTimes
}

export function getSecurityTypes() {
  return configData.securityTypes
}

export function getOperatorQuestionsArray() {
  return configData.questions.operator
}

export function getOperatorForemanQuestionsArray() {
  return configData.questions.operatorForeman
}

export function getDriverQuestionsArray() {
  return configData.questions.driver
}

export function getMobileDriverShowDDTButton() {
  return configData.mobileDriverShowDDTButton
}

export function getExportReports() {
  return configData.exportReports
}

export function getMaterials() {
  return configData.availableMaterials
}

export function getForemanCategories() {
  return configData.foremanCategories
}

export function getPileTypes() {
  return configData.pileTypes
}

export function getTeamTypes() {
  return configData.teamTypes
}

export function getFixedProjectScheduleHeight() {
  return configData.fixedProjectScheduleHeight
}

export function getPlannerResourcesSelectedOnBottom() {
  return configData.plannerResourcesSelectedOnBottom
}

export function getDraggableSchedule() {
  return configData.draggableSchedule
}

export function getUseBiggerFontInSchedule() {
  return configData.useBiggerFontInSchedule
}

export function getShowProjectDescriptionInSchedule() {
  return configData.showProjectDescriptionInSchedule
}

export function getCloseProgramHeader() {
  return configData.closeProgramHeader
}

export function getActivityRequestStatuses() {
  return configData.activityRequestStatuses
}

export function getVehicleRequestStatuses() {
  return configData.vehicleRequestStatuses
}

export function getVehicleOperations() {
  return configData.vehicleOperations
}

export function getResetAllowanceOnWeekend() {
  return configData.resetAllowanceOnWeekend
}

export function getDpiStatuses() {
  return configData.dpiStatuses
}

export function getClockInTransferIsEnabled() {
  return configData.clockInTransferIsEnabled
}

export function getClockInGeoRefIsEnabled() {
  return configData.clockInGeoRefIsEnabled
}

export function getPriorities() {
  return configData.priorities
}

export function getPlannerEyeIsEnabled() {
  return configData.plannerEyeIsEnabled
}

export function getShowClockInTimeAlert() {
  return configData.showClockInTimeAlert
}

export function getLunchBreak() {
  return configData.lunchBreak
}

export function getPlannerCopyWholeDayIsEnabled() {
  return configData.plannerCopyWholeDayIsEnabled
}
