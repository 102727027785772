import { getPriorities } from '@/utils/api/config'
import { translations } from '@/utils/common'

export default {
  state: {
    priorities: {},
    prioritiesSelect: []
  },

  getters: {
    priorities(state) {
      return state.priorities
    },

    prioritiesSelect(state) {
      return state.prioritiesSelect
    }
  },

  actions: {
    initPriorities({ commit }) {
      commit('priorities/update', getPriorities())
    }
  },

  mutations: {
    'priorities/update'(state, payload) {
      // update priorities
      state.priorities = payload.reduce((map, es) => {
        map[es.value] = {
          ...es,
          text: translations['priority_' + es.value]
        }
        return map
      }, {})

      // update priorities for select
      state.prioritiesSelect = payload.map(es => ({
        ...es,
        value: es.value,
        text: translations['priority_' + es.value]
      }))
    }
  }
}
