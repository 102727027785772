const initialState = () => ({
  employeeIdUsed: [],
  supplierIdUsed: []
});

const state = initialState();
const getters = {};
const actions = {
  // Use this action creator to insert 1 id
  // Payload String
  insertIntoEmployeeIdUsed({ commit }, payload) {
    commit("insertIntoEmployeeIdUsed", payload);
  },

  // Use this action creator to delete 1 id
  // Payload String
  removeEmployeeIdUsed({ commit }, payload) {
    commit("removeEmployeeIdUsed", payload);
  },

  // Use this action creator to delete multiple ids
  // Payload [Number]
  removeEmployeeIdUsedMultiple({ commit }, payload) {
    commit("removeEmployeeIdUsedMultiple", payload);
  },

  // Use this action creator to change the whole array
  // Payload [String]
  changeEmployeeIdUsed({ commit }, payload) {
    commit("changeEmployeeIdUsed", payload);
  }
};

const mutations = {
  insertIntoEmployeeIdUsed: (state, data) => state.employeeIdUsed.push(data),
  removeEmployeeIdUsed: (state, data) => (state.employeeIdUsed = state.employeeIdUsed.filter(id => id !== data)),
  changeEmployeeIdUsed: (state, data) => {
    state.employeeIdUsed = data;
  },
  removeEmployeeIdUsedMultiple: (state, data) => {
    state.employeeIdUsed = state.employeeIdUsed.filter(id => !data.includes(id));
  }
};

export default { state, getters, actions, mutations };
