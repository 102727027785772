import Vue from "vue";
const eventBus = new Vue({
  methods: {
    drop(entity) {
      this.$emit("drop", entity);
    },

    updateClockIn(table) {
      this.$emit("clock-in-table-update", table);
    },

    rowDropOnSameScheule(item) {
      this.$emit("row-drop-on-same-schedule", item);
    },

    sideNavDisabler(bool) {
      this.$emit("should-disable-nav", bool);
    }
  }
});

export default eventBus;
