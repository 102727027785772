import CapabilityService from '@/service/capability/CapabilityService'

export default {
  state: {
    capabilities: []
  },

  getters: {
    capabilities(state) {
      return state.capabilities
    }
  },

  actions: {
    initCapability({ commit }) {
      CapabilityService.findAll().then(payload => commit('capability/update', payload))
    }
  },

  mutations: {
    'capability/update'(state, payload) {
      // update capabilities
      state.capabilities = payload
    }
  }
}
