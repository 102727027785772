<template>
  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#005792" stroke-width="0.6">
    <g id="SVGRepo_bgCarrier" stroke-width="0" />
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
    <g id="SVGRepo_iconCarrier">
      <circle cx="12" cy="12" r="9" stroke="#005792" stroke-width="0.8" />
      <path d="M12 12H21" stroke="#005792" stroke-linecap="round" stroke-width="0.8" />
      <path d="M12 3V11.9379C12 11.9777 12.0158 12.0158 12.0439 12.0439L18 18" stroke="#005792" stroke-width="0.8" stroke-linecap="round" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "PieChartIcon"
};
</script>
