const initialState = () => {
  return {
    userInfo: {}
  }
}

const state = initialState()

const getters = {}

const actions = {
  setUserInfo: ({ commit }, payload) => {
    commit('mutateUserInfo', payload)
  }
}
const mutations = {
  mutateUserInfo: (state, payload) => {
    state.userInfo = payload
  }
}

export default { state, getters, actions, mutations }
