import moment from "moment";
const initialState = () => {
  const todaysDateTime = new Date().setHours(0, 0, 0, 0);
  return {
    todaysDate: moment(new Date()).format("YYYY-MM-DD"),
    todaysDateTime: moment(todaysDateTime)
      .toDate()
      .getTime(),
    currentDate: "",
    currentDateTime: 0
  };
};

const state = initialState();
const getters = {};
const actions = {
  // payload - STRING (YYYY-MM-DD)
  changeDate({ commit }, payload) {
    commit("changeDate", payload);
  },

  // payload - STRING (YYYY-MM-DD)
  changeCurrentDate({ commit }, payload) {
    commit("changeCurrentDate", payload);
  },

  // payload - NUMBER 0000000
  changeCurrentDateTime({ commit }, payload) {
    commit("changeCurrentDateTime", payload);
  }
};

const mutations = {
  changeDate: (state, date) => (state.todaysDate = date),
  changeCurrentDate: (state, date) => (state.currentDate = date),
  changeCurrentDateTime: (state, dateTime) => (state.currentDateTime = dateTime)
};

export default { state, getters, actions, mutations };
