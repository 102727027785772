<template>
  <v-dialog :value="show" persistent scrollable width="500px">
    <v-card class="pa-2">
      <v-card-title>
        <slot name="title"></slot>
      </v-card-title>

      <v-card-subtitle>
        <slot name="card-image"></slot>
      </v-card-subtitle>

      <v-card-text>
        <slot name="message"></slot>
      </v-card-text>

      <v-card-actions>
        <slot name="card-action"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MessageDialog',

  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
.v-dialog > .v-card > .v-card__title {
  display: flex;
  justify-content: center;
}
</style>
