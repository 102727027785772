import apolloUtil from '@/service/ApolloUtil'

import capabilities from '@/service/capability/CapabilitiesQuery.gql'
import capabilitiesByAccountId from '@/service/capability/CapabilitiesByAccountIdQuery.gql'

class CapabilityService {

  findAll() {
    return apolloUtil.query(capabilities)
      .then((response) => response?.data?.capabilities)
  }

  capabilitiesByAccountId(params) {
    return apolloUtil.query(capabilitiesByAccountId, params)
      .then((response) => response?.data?.capabilitiesByAccountId)
  }

}

export default new CapabilityService()
