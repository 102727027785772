const initialState = () => {
  return {
    clockInsDate: "",
    confirmedClockIns: false
  };
};

const state = initialState();
const getters = {};
const actions = {
  // payload - STRING (YYYY-MM-DD)
  setClockInDateAndConfirmed({ commit }, payload) {
    const { date, confirmed } = payload;
    commit("setDate", date);
    commit("setConfirmed", confirmed);
  }
};

const mutations = {
  setDate: (state, date) => (state.clockInsDate = date),
  setConfirmed: (state, confirmed) => (state.confirmedClockIns = confirmed)
};

export default { state, getters, actions, mutations };
