import { getVehicleOperations } from '@/utils/api/config'
import translationsUtil from '@/utils/common/translationsUtil'

export default {
  state: {
    statusToAllowShowDate: ['CLOSED', 'IN_PROGRESS', 'STANDBY'],
    vehicleOperations: {}
  },

  getters: {
    statusToAllowShowDate(state) {
      return state.statusToAllowShowDate
    },

    vehicleOperations(state) {
      return state.vehicleOperations
    }
  },

  actions: {
    initVehicleOperations({ commit }) {
      commit('vehicleOperations/update', getVehicleOperations())
    }
  },

  mutations: {
    'vehicleOperations/update'(state, payload) {
      // update vehicleOperations
      state.vehicleOperations = payload.map(vs => ({
        ...vs,
        text: translationsUtil('vehicleOperation_' + vs.value)
      }))
    }
  }
}
