<template>
  <svg fill="#005792" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 442 442" xml:space="preserve" stroke="#005792" stroke-width="0.00442">
    <g id="SVGRepo_bgCarrier" stroke-width="0" />
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          d="M40,291v75c0,5.522,4.478,10,10,10h214.667c5.522,0,10-4.478,10-10v-75c0-5.522-4.478-10-10-10H50 C44.478,281,40,285.478,40,291z M254.667,356h-75.336l55-55h20.336V356z M60,301h75.335l-27.928,27.929 c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929s5.118-0.977,7.071-2.929L163.62,301h42.426l-55,55H60 V301z"
        />
        <path
          d="M343.722,180.904c5.484,0.238,10.205-3.989,10.472-9.505L361.527,20h40.936l9.103,192.081 c0.254,5.355,4.676,9.526,9.981,9.526c0.159,0,0.32-0.004,0.481-0.011c5.517-0.262,9.776-4.946,9.516-10.463L421.989,9.526 C421.736,4.193,417.339,0,412,0h-60c-5.335,0-9.73,4.188-9.988,9.517l-7.795,160.916 C333.949,175.948,338.205,180.637,343.722,180.904z"
        />
        <path
          d="M436.656,242.15l-211-111c-3.101-1.632-6.827-1.521-9.825,0.289C212.832,133.25,211,136.497,211,140v38.94l-90.844-47.79 c-3.101-1.632-6.827-1.521-9.825,0.289c-2.999,1.811-4.831,5.058-4.831,8.561v38.94l-90.844-47.79 c-3.102-1.632-6.827-1.521-9.825,0.289C1.832,133.25,0,136.497,0,140v292c0,5.522,4.478,10,10,10h294.667H392h40 c5.522,0,10-4.478,10-10V251C442,247.286,439.942,243.879,436.656,242.15z M314.667,422V301H382v121H314.667z M422,422h-20V291 c0-5.522-4.478-10-10-10h-87.333c-5.522,0-10,4.478-10,10v131H20V156.56l90.844,47.79c3.102,1.632,6.827,1.521,9.825-0.289 c2.999-1.811,4.831-5.058,4.831-8.561v-38.94l90.844,47.79c3.102,1.632,6.827,1.521,9.825-0.289 c2.999-1.811,4.831-5.058,4.831-8.561v-38.94l191,100.479V422z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PlantIcon"
};
</script>
