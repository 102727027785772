export default {
  state: {
    filter: {
      AccountPagination: {
        sorts: {
          'name': 'ASC'
        }
      },

      ActivityRequestParams: {
        employeeStatuses: ['VAC', 'LEA'],
        statuses: ['PENDING']
      },
      ActivityRequestPagination: {
        sorts: {
          'createdAt': 'ASC'
        }
      },

      DpiParams: {
        statuses: ['PENDING', 'IN_PROGRESS']
      },
      DpiPagination: {
        sorts: {
          'createdAt': 'ASC'
        }
      },

      LocalUnitPagination: {
        sorts: {
          'name': 'ASC'
        }
      },

      ManufacturerPagination: {
        sorts: {
          'name': 'ASC'
        }
      },

      NewsPagination: {
        sorts: {
          'instant': 'DESC',
          'title': 'ASC'
        }
      },

      ProjectPagination: {
        sorts: {
          'keyCode': 'ASC'
        }
      },

      PropertyPagination: {
        sorts: {
          'key': 'ASC'
        }
      },

      SupplierPagination: {
        sorts: {
          'keyCode': 'ASC'
        }
      },

      VehiclePagination: {
        sorts: {
          'keyCode': 'ASC'
        }
      },

      VehicleRequestParams: {
        types: ['REP'],
        priorities: [2],
        statuses: ['PENDING']
      },
      VehicleRequestPagination: {
        sorts: {
          'priority': 'DESC',
          'createdAt': 'ASC'
        }
      }
    }
  },

  getters: {
    'preferences/get/filter'(state) {
      return state.filter
    }
  },

  actions: {
    'preferences/set/filter'({ commit }, payload) {
      commit('preferences/mutate', { kind: 'filter', ...payload })
    }
  },

  mutations: {
    'preferences/mutate'(state, payload) {
      state[payload.kind][payload.name] = payload.value
    }
  }
}
