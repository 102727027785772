import axios from 'axios'
import conf from './conf'

const axiosInstance = axios.create({
  baseURL: conf.value('VUE_APP_GRAPHQL_HTTP')
})

axiosInstance.interceptors.request.use(
  function(config) {
    return config
  },
  function(error) {
    return error
  }
)

axiosInstance.interceptors.response.use(function(response) {
  return response.data
})

export default axiosInstance
