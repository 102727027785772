import { getActivityRequestStatuses } from '@/utils/api/config'
import { translations } from '@/utils/common'

export default {
  state: {
    activityRequestStatuses: {},
    activityRequestStatusesSelect: []
  },

  getters: {
    activityRequestStatuses(state) {
      return state.activityRequestStatuses
    },

    activityRequestStatusesSelect(state) {
      return state.activityRequestStatusesSelect
    }
  },

  actions: {
    initActivityRequestStatuses({ commit }) {
      commit('activityRequestStatuses/update', getActivityRequestStatuses())
    }
  },

  mutations: {
    'activityRequestStatuses/update'(state, payload) {
      // update activityRequestStatuses
      state.activityRequestStatuses = payload.reduce((map, es) => {
        map[es.value.toUpperCase()] = {
          ...es,
          text: translations[es.value.toLowerCase()]
        }
        return map
      }, {})

      // update activityRequestStatuses for select
      state.activityRequestStatusesSelect = payload.map(es => ({
        value: es.value.toUpperCase(),
        text: translations[es.value.toLowerCase()]
      }))
    }
  }
}
