import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import Cookies from 'js-cookie'
import conf from './utils/api/conf'
import { auth } from '../src/utils/api'
import store from './store'

export const AUTH_TOKEN = 'apollo-token'

export let clientApollo

Vue.use(VueApollo)

// auth link with custom headers
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'authorization': `Bearer ${Cookies.get(AUTH_TOKEN)}` || null,
      'tom-client-version': store.state.clientVersion.clientVersion || null
    }
  })
  return forward(operation)
})

// retry link
const retryLink = new RetryLink({
  delay: {
    initial: 200
  },

  attempts: {
    max: 2,
    retryIf: (error) => {
      // when new version is available show modal to reload page (checked on top of 'tom-client-version' header)
      if (error.statusCode === 409) {
        store.dispatch('clientVersionOpenModal')
        return false
      }

      if (error.statusCode === 401) {
        const token = Cookies.get(AUTH_TOKEN)
        if (token) {
          return new Promise(resolve => {
            auth.refresh(token).then(newToken => {
              Cookies.set(AUTH_TOKEN, newToken)
              resolve(false)
            }).catch(() => resolve(true))
          })
        }
        return false
      }

      return true
    }
  }
})

// apollo client
const defaultOptions = {
  httpEndpoint: conf.value('VUE_APP_GRAPHQL_HTTP'),
  tokenName: AUTH_TOKEN,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: ApolloLink.from([authLink, retryLink])
}

// call this in the Vue app file
export function createProvider(options = {}) {
  // create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options
  })
  apolloClient.wsClient = wsClient

  // set the client to be invoked outside Vue components
  clientApollo = apolloClient

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    }
  })
}

// manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (token) {
    Cookies.set(AUTH_TOKEN, token)
  }

  if (apolloClient) {
    if (apolloClient.wsClient) {
      restartWebsockets(apolloClient.wsClient)
    }
    await apolloClient.resetStore()
  }
}

// manually call this when user log out
export async function onLogout(apolloClient) {
  store.dispatch('setUserInfo', {})

  if (Cookies.get(AUTH_TOKEN)) {
    Cookies.remove(AUTH_TOKEN)
  }

  if (apolloClient) {
    if (apolloClient.wsClient) {
      restartWebsockets(apolloClient.wsClient)
    }
    await apolloClient.resetStore()
  }
}
