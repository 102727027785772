const initialState = () => {
  return {
    draggedSchedule: {
      id: null,
      orderNumber: 0,
      project: {},
      teams: []
    }
  }
}

// localStorage item key
const draggedScheduleKey = 'dragged-schedule'

const state = initialState()

const getters = {}

const mutations = {
  setDraggedSchedule: (state, payload) => (state.draggedSchedule = payload)
}

const actions = {
  getDraggedSchedule: async ({ commit, state }) => {
    if (localStorage.getItem(draggedScheduleKey)) {
      const payload = JSON.parse(localStorage.getItem(draggedScheduleKey))

      commit('setDraggedSchedule', payload)
    }

    return state.draggedSchedule
  },

  changeDraggedSchedule: ({ commit }, payload) => {
    // set data in localstorage
    if (localStorage.getItem(draggedScheduleKey)) {
      localStorage.removeItem(draggedScheduleKey)
    }

    localStorage.setItem(draggedScheduleKey, JSON.stringify(payload))

    commit('setDraggedSchedule', payload)
  },

  removeDraggedSchedule: ({ commit }) => {
    localStorage.removeItem(draggedScheduleKey)
    commit('setDraggedSchedule', initialState().draggedSchedule)
  }
}

export default { state, getters, mutations, actions }
