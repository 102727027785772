import { clientApollo } from '@/vue-apollo'

class ApolloUtil {

  query(query, variables) {
    return clientApollo.query({ query, variables, fetchPolicy: 'no-cache' })
      .catch((error) => this.handleError(error))
  }

  mutate(mutation, variables, handleError) {
    return clientApollo.mutate({ mutation, variables })
      .catch((error) => this.handleError(error, handleError))
  }

  handleError(error, handleError) {
    if (handleError) {
      alert(error)
    } else {
      throw error
    }
  }

}

export default new ApolloUtil()
