// all the values with $ will be overwritten from foreign configuration
const CONFIG = {
  VUE_APP_GRAPHQL_HTTP: '$VUE_APP_GRAPHQL_HTTP',
  VUE_APP_REDIRECT_URL: '$VUE_APP_REDIRECT_URL',
  VUE_APP_REPORTS_URL: '$VUE_APP_REPORTS_URL'
}

export default {
  value(key) {
    let value = CONFIG[key]

    // this value wasn't overwritten => get it from process.env
    if (value && value === '$' + key) {
      value = process.env[key]
    }

    return value
  }
}
