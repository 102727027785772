import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import PlantIcon from "../components/Icons/PlantIcon";
import PileIcon from "../components/Icons/PileIcon";
import PieChartIcon from "../components/Icons/PieChartIcon";

Vue.use(Vuetify);

export function updateVuetifyLightTheme(vuetifyInstance, newThemeColors) {
  vuetifyInstance.theme.themes.light.main = newThemeColors.mainColor;
  vuetifyInstance.theme.themes.light.border = newThemeColors.borderColor;
  vuetifyInstance.theme.themes.light.lighterGrey = newThemeColors.lighterGreyColor;
  vuetifyInstance.theme.themes.light.darkerGrey = newThemeColors.darkerGreyColor;
  vuetifyInstance.theme.themes.light.darkdarkGrey = newThemeColors.darkdarkGreyColor;
  vuetifyInstance.theme.themes.light.projectColor = newThemeColors.projectColor;
  vuetifyInstance.theme.themes.light.vehicleColor = newThemeColors.vehicleColor;
  vuetifyInstance.theme.themes.light.supplierColor = newThemeColors.supplierColor;
  vuetifyInstance.theme.themes.light.employeeColor = newThemeColors.employeeColor;
  vuetifyInstance.theme.themes.light.siteColor = newThemeColors.siteColor;
  vuetifyInstance.theme.themes.light.error = newThemeColors.errorColor;
  vuetifyInstance.theme.themes.light.warning = newThemeColors.warningColor;
  vuetifyInstance.theme.themes.light.lightOrange = newThemeColors.lightOrangeColor;
  vuetifyInstance.theme.themes.light.unusedItems = newThemeColors.unusedItemsColor;
}

export default new Vuetify({
  options: { customProperties: true },
  icons: {
    values: {
      plant: { component: PlantIcon },
      pile: { component: PileIcon, props: { color: "main" } },
      pileRed: { component: PileIcon, props: { color: "red" } },
      pileGreen: { component: PileIcon, props: { color: "green" } },
      pieChart: { component: PieChartIcon }
    }
  }
});
