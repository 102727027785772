export default {
  state: {
    clientVersion: null,
    clientVersionShowModal: false
  },

  actions: {
    clientVersionCheck({ state, commit, dispatch }, remoteClientVersion) {
      // remove the key to force the closing during the copy mode
      localStorage.removeItem('forceCloseOrigCopyModeWindow')

      // current saved version
      const currentVersion = state.clientVersion

      if (currentVersion === null || state.clientVersion !== remoteClientVersion) {
        // update the client version
        commit('clientVersionMutate', remoteClientVersion)

        // open the modal to reload the page (if not already done)
        if (currentVersion && !state.clientVersionShowModal) {
          return dispatch('clientVersionOpenModal')
        }
      }

      // close the modal where there is no changes
      dispatch('clientVersionCloseModal')
    },

    clientVersionOpenModal({ state, commit }) {
      if (!state.clientVersionShowModal) {
        // force the closing during the copy mode
        localStorage.setItem('forceCloseOrigCopyModeWindow', 'true')

        // show the version popup
        commit('clientVersionShowModalMutate', true)
      }
    },

    clientVersionCloseModal({ commit }) {
      commit('clientVersionShowModalMutate', false)
    }
  },

  mutations: {
    clientVersionMutate(state, payload) {
      state.clientVersion = payload
    },

    clientVersionShowModalMutate(state, payload) {
      state.clientVersionShowModal = payload
    }
  }
}
