import { translations } from '@/utils/common'

export default {
  state: {
    // FIXME: retrieve activityRequestDayParts from db
    activityRequestDayParts: [{
      value: '1',
      text: translations.morning
    }, {
      value: '2',
      text: translations.afternoon
    }],

    activityRequestDayPartsMap: {
      '1': translations.morning,
      '2': translations.afternoon
    }
  },

  getters: {
    activityRequestDayParts(state) {
      return state.activityRequestDayParts
    },

    activityRequestDayPartsMap(state) {
      return state.activityRequestDayPartsMap
    }
  }
}
