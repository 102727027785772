export default {
  state: {
    clockInConfirmedTypes: {}
  },

  getters: {
    clockInConfirmedTypes(state) {
      return state.clockInConfirmedTypes
    }
  },

  actions: {
    initClockInConfirmedTypes({ commit }) {
      commit('clockInConfirmedTypes/update')
    }
  },

  mutations: {
    'clockInConfirmedTypes/update'(state) {
      // update clockInConfirmedTypes
      state.clockInConfirmedTypes = {
        NOT_CONFIRMED: 0,
        SUPERVISOR_CONFIRMED: 1,
        ADMIN_CONFIRMED: 2
      }
    }
  }
}
