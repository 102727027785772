const initialState = () => ({
  vehicleIdUsed: []
});

const state = initialState();
const getters = {};
const actions = {
  // Use this action creator to insert 1 id
  // Payload String
  insertIntoVehicleIdUsed({ commit }, payload) {
    commit("insertIntoVehicleIdUsed", payload);
  },
  // Use this action creator to delete 1 id
  // Payload String
  removeVehicleIdUsed({ commit }, payload) {
    commit("removeVehicleIdUsed", payload);
  },

  // Use this action creator to delete multiple ids
  // payload [Number]
  removeVehicleIdUsedMultiple({ commit }, payload) {
    commit("removeVehicleIdUsedMultiple", payload);
  },

  // Use this action creator to change the whole array
  // Payload [String]
  changeVehicleIdUsed({ commit }, payload) {
    commit("changeVehicleIdUsed", payload);
  }
};

const mutations = {
  insertIntoVehicleIdUsed: (state, data) => state.vehicleIdUsed.push(data),
  removeVehicleIdUsedMultiple: (state, data) => {
    state.vehicleIdUsed = state.vehicleIdUsed.filter(id => !data.includes(id));
  },
  removeVehicleIdUsed: (state, data) => (state.vehicleIdUsed = state.vehicleIdUsed.filter(id => id !== data)),
  changeVehicleIdUsed: (state, data) => {
    state.vehicleIdUsed = data;
  }
};

export default { state, getters, actions, mutations };
