const initialState = () => ({
  supplierIdUsed: []
});

const state = initialState();
const getters = {};
const actions = {
  // Use this action creator to insert 1 id
  // Payload String
  insertIntoSupplierIdUsed(/*{ commit }, payload*/) {
    return;
    // commit("insertIntoSupplierIdUsed", payload);
  },
  // Use this action creator to delete 1 id
  // Payload String
  removeSupplierIdUsed(/*{ commit }, payload*/) {
    // commit("removeSupplierIdUsed", payload);
  },

  // Use this action creator to delete multiple ids
  // Payload [String]
  removeSupplierIdUsedMultiple(/*{ commit }, payload*/) {
    // commit("removeSupplierIdUsedMultiple", payload);
  },

  // Use this action creator to change the whole array
  // Payload [String]
  changeSupplierIdUsed({ commit }, payload) {
    commit("changeSupplierIdUsed", payload);
  }
};

const mutations = {
  insertIntoSupplierIdUsed: (state, data) => state.supplierIdUsed.push(data),
  removeSupplierIdUsedMultiple: (state, data) => {
    state.supplierIdUsed = state.supplierIdUsed.filter(id => !data.includes(id));
  },
  removeSupplierIdUsed: (state, data) => (state.supplierIdUsed = state.supplierIdUsed.filter(id => id !== data)),
  changeSupplierIdUsed: (state, data) => {
    state.supplierIdUsed = data;
  }
};

export default { state, getters, actions, mutations };
