import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { createProvider } from './vue-apollo'
import { VueMaskDirective } from 'v-mask'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false

// leaflet map
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/src/images/marker.svg'),
  iconUrl: require('leaflet/src/images/marker.svg'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

// use mask directive globally
Vue.directive('mask', VueMaskDirective)

// import apexchart component globally
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// init common data
export function initCommonData(store) {
  store.dispatch('initActivityRequestStatuses')
  store.dispatch('initCapability')
  store.dispatch('initClockInConfirmedTypes')
  store.dispatch('initDpiStatuses')
  store.dispatch('initEmployeeStatus')
  store.dispatch('initPriorities')
  store.dispatch('initVehicleOperations')
  store.dispatch('initVehicleRequestStatuses')
}

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
