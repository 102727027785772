import Cookies from 'js-cookie'
import axios from './axios'
import store from '../../store'

export default {
  login: async (username, password) => {
    return await axios.post('login', { username, password })
  },

  logout: async () => {
    store.dispatch('setUserInfo', {})
    Cookies.remove('apollo-token')
  },

  refresh: async () => {
    let config = {
      headers: {
        authorization: `Bearer ${Cookies.get('apollo-token')}`,
        refreshToken: true
      }
    }
    return await axios.get('refresh-token', config)
  }
}
