import apolloUtil from '@/service/ApolloUtil'

import employeeStatusesQuery from '@/service/employeeStatus/EmployeeStatusesQuery.gql'

class EmployeeStatusService {

  findAll() {
    return apolloUtil.query(employeeStatusesQuery)
      .then((response) => response?.data?.employeeStatuses)
  }

}

export default new EmployeeStatusService()
