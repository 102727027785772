<template>
  <v-dialog :value="display" max-width="600px" persistent>
    <v-card class="pa-5">
      <v-card-text>
        <section>
          <div class="d-flex justify-center mb-2">
            <v-icon size="40px" color="warning">{{ icons.mdiAlert }}</v-icon>
          </div>
          <p>
            {{ translations.copyWarning }}
          </p>
        </section>
      </v-card-text>
      <v-action class="d-flex justify-center">
        <v-btn small color="main" dark depressed @click="$emit('exitCopy')">
          {{ translations.exitFromCopy }}
        </v-btn>
      </v-action>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAlert } from "@mdi/js";
import { translations } from "@/utils/common";

export default {
  name: "ScheduleCopyWarningDialog",
  props: {
    display: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    translations: () => translations,
    icons: () => ({
      mdiAlert
    })
  }
};
</script>

<style></style>
